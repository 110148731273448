.container {
  row-gap: var(--lp-size-4);
  column-gap: var(--lp-size-4);
  grid-template-areas: "label"
                       "list";
  display: grid;
}

.container:has(.add) {
  grid-template-areas: "label"
                       "list"
                       "add";
}

.container:has(.list[data-empty]) {
  grid-template-columns: min-content min-content min-content;
  grid-template-areas: "label label label"
                       "list symbol add";
}

.group {
  display: contents;
}

.label {
  grid-area: label;
}

.list {
  align-items: center;
  gap: var(--lp-size-4);
  border-radius: var(--lp-border-radius-regular);
  outline: 0;
  flex-flow: wrap;
  grid-area: list;
  min-width: 0;
  display: flex;
}

.list[data-empty] {
  font: var(--lp-text-small-1-regular);
  color: var(--lp-color-text-field-placeholder);
  white-space: nowrap;
}

.list[data-focus-visible] {
  box-shadow: 0 0 0 2px var(--lp-color-bg-ui-primary), 0 0 0 4px var(--lp-color-shadow-interactive-focus);
}

.tag {
  align-items: center;
  gap: var(--lp-size-3);
  height: var(--lp-size-24);
  font: var(--lp-text-small-1-regular);
  color: var(--lp-color-text-ui-primary-base);
  background-color: var(--lp-color-bg-interactive-secondary-base);
  border: 1px solid var(--lp-color-bg-ui-tertiary);
  border-radius: var(--lp-border-radius-medium);
  outline: 0;
  padding-inline-start: var(--lp-size-8);
  padding-inline-end: var(--lp-size-8);
  display: flex;
  overflow: hidden;
}

.tag:has(.avatar) {
  padding-inline-start: var(--lp-size-4);
}

.tag[data-allows-removing] {
  padding-inline-end: 0;
}

.tag[data-disabled] {
  color: var(--lp-color-text-interactive-disabled);
}

.tag[data-focus-visible] {
  z-index: 1;
  box-shadow: 0 0 0 2px var(--lp-color-bg-ui-primary), 0 0 0 3px var(--lp-color-border-field-focus);
}

.tag[data-selected] {
  color: var(--lp-color-bg-interactive-primary-active);
  background-color: var(--lp-color-bg-ui-primary);
  border-color: var(--lp-color-border-field-active);
}

.icon, .avatar {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.add {
  grid-area: add;
  display: flex;
}

.symbol {
  display: none;
}

.container:has(.list[data-empty]) .symbol {
  grid-area: symbol;
  display: flex;
}
